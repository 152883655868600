import React, {useCallback, useEffect, useState} from "react";
import {FormField
    , PropertyFilterProps, Button, Container, Header, Grid} from "@amzn/awsui-components-react-v3";
import {
    DataStoreFields,
    filterPropertyConfigMap,
    FilterPropertyGroups
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {STATES} from "src/interfaces/workflow";
import {
    convertFilterTokensToUrlQuery,
    convertFilterUrlQueryToFilterTokens
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-transformers";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {useHistory} from "react-router-dom";
import { PropertyFilter } from '@amzn/awsui-components-react-v3/polaris';
import {
    buildFilterOptionsForProperty, getSubProgramList
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters.utils";
import {PROGRAM_NAMES_ENUM, TARGETED_WEBSITES} from "src/constants/workflow-instance";

export const FilterInput = () => {
    const [filterTokens, setFilterTokens] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and'
    })

    const params = new URLSearchParams(location.search);
    const filterQuery = params.get(QueryParams.FILTER_QUERY_KEY) || '';
    const history = useHistory();

    useEffect(() => {
        if(filterQuery){
            const tokens = convertFilterUrlQueryToFilterTokens(JSON.parse(filterQuery));
            setFilterTokens({tokens, operation: 'and'});
        }
    }, [filterQuery])

    const onSubmitClick = useCallback(() => {
        const newFilterQuery = convertFilterTokensToUrlQuery(filterTokens);
        params.delete(QueryParams.FILTER_QUERY_KEY);
        const paramsWithFilterQuery = `${params.toString()}&${QueryParams.FILTER_QUERY_KEY}=${encodeURIComponent(JSON.stringify(newFilterQuery))}`;
        history.replace({search: paramsWithFilterQuery})
    }, [filterTokens, params, history]);

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Container
                header={
                    <Header variant="h2">
                        {"Filters"}
                    </Header>
                }
            >
                <FormField
                >
                    <Grid
                        gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
                    >
                    <PropertyFilter
                        onChange={({ detail }) => setFilterTokens(detail)}
                        query={filterTokens}
                        hideOperations
                        virtualScroll
                        tokenLimit={4}
                        i18nStrings={{
                            filteringAriaLabel: "Type in to explore and apply filters to computed metrics",
                            filteringPlaceholder: "Type in to explore and apply filters to computed metrics",
                            cancelActionText: "Cancel",
                            applyActionText: "Apply",
                            allPropertiesLabel: "All",
                            clearFiltersText: "Clear Filters",
                            operatorsText: "Operators",
                            operationAndText: "AND",
                            operationOrText: "OR",
                            operatorLessText: "Less than",
                            operatorLessOrEqualText: "Less than or equal to",
                            operatorGreaterText: "Greater than",
                            operatorGreaterOrEqualText: "Greater than or equal to",
                            operatorContainsText: "Regex match",
                            operatorEqualsText: "Equal to",
                            operatorDoesNotEqualText: "Not equal to",
                            tokenLimitShowMore: "Show More",
                            tokenLimitShowFewer: "Show fewer",
                            propertyText: "Property filters",
                            groupPropertiesText: "Property filters",
                        }}
                        customGroupsText={[
                            {
                                properties: "Time Filters",
                                values: "Time filter values",
                                group: FilterPropertyGroups.TIME_FILTERS
                            },
                            {
                                properties: "Multi select filters",
                                values: "Multi select filter values",
                                group: FilterPropertyGroups.MULTI_SELECT_FILTERS
                            }
                        ]}
                        filteringOptions={[
                            ...buildFilterOptionsForProperty(DataStoreFields.WORKFLOW_STATUS, [STATES.FAILED, STATES.RUNNING, STATES.STOPPED]),
                            ...buildFilterOptionsForProperty(DataStoreFields.STAGE_STATUS, [STATES.FAILED, STATES.RUNNING, STATES.STOPPED]),
                        ]}
                        filteringProperties={Object.values(filterPropertyConfigMap)}
                    />

                    <Button variant="primary" onClick={onSubmitClick} data-testid="filter-input-submit">Apply Filters</Button>
                    </Grid>
                </FormField>
            </Container>
        </form>
    )
}
