/* istanbul ignore file */
import {Calendar, DateInput, FormField, PropertyFilterProps, Multiselect} from "@amzn/awsui-components-react-v3";
import React from "react";
import {FAILED_WORKFLOW_TYPE_OPTIONS} from "src/constants/domain-tracker";
import {PropertyFilterOperatorForm} from "@amzn/awsui-collection-hooks";
import {
    getSubProgramList
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters.utils";
import {PRODUCT_STRATEGY_CONSTANTS, PROGRAM_NAMES_ENUM, TARGETED_WEBSITES} from "src/constants/workflow-instance";
import { OptionDefinition } from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";

export enum DataStoreFields {
    WORKFLOW_CREATE_TIMESTAMP = 'workflowcreatetimestamp',
    STAGE_CREATION_TIMESTAMP = 'stagecreationtimestamp',
    STAGE_LAST_UPDATE_TIMESTAMP = 'stagelastupdatetimestamp',
    STAGE_ERROR = 'stageerror',
    STAGE_NAME = 'stagename',
    STAGE_STATUS = 'stagestatus',
    PROGRAM = 'program',
    PRODUCT = 'product',
    WORKFLOW_STATUS = 'state',
    ERROR = 'error',
    SUB_PROGRAM = 'subprogram',
    MARKET_PLACE = 'targetedwebsite',
    FAILED_WORKFLOW_TYPE = 'failuretype',
    IS_ROR_OPEN = 'isRoROpen-Number',
    DOMAIN = 'domain',
    ATTEMPT_LAST_UPDATE_TIMESTAMP = 'attemptlastupdatetimestamp',
    WORKFLOW_START_TIME = 'workflowruncreatetimestamp',
    WORKFLOW_END_TIME = 'workflowrunlastupdatetimestamp',
    WORKFLOW_LAST_UPDATE_TIMESTAMP = 'workflowlastupdatetimestamp',
    USE_CASE = "usecase",
    VARIANT = "variant"
}

export enum FilterOperation {
    MATCH = 'match',
    MATCH_ANY = 'matchAny',
    RANGE = 'range',
    LIKE = 'like',
    REGEX = 'regex'
}

export enum QueryVerb {
    MUST = 'must',
    MUST_NOT = 'mustNot',
    FILTER = 'filter'
}

export const START_TIME_MIN = 1656683226000;
export const END_TIME_MAX = 2609498495000;

export enum FilterTokenOperators {
    EQUAL = '=',
    NOT_EQUAL = '!=',
    REGEX = ':',
    GREATER_THAN_EQUAL = '>=',
    LESS_THAN_EQUAL = '<='
}

export enum FilterPropertyGroups {
    TIME_FILTERS = 'Time Filters',
    MULTI_SELECT_FILTERS = '"Multiselect filters"'
}

export const getMultiselectForm = (options: string[]): PropertyFilterOperatorForm<string> =>
    ({value, onChange}) => {
        const formOptions = options.map(value => ({value, label: value}));
        const currentValue = value ? value.split(',') : [];
        const [selectedOptions, setSelectedOptions] = React.useState<readonly OptionDefinition[]>(currentValue.map(value => ({
            value,
            label: value
        })));
        return (
            <div style={{height: '250px'}}>
                {/* Note: this is a workaround (Adding a div with inline height) to add multiselect component to property filter
                component. The inherent support is still under implementation by Polaris team, till then we have used a div
                with specific height to get multiselect rendering right
                */}
                <FormField stretch>
                    <Multiselect
                        data-testid="multi-select-input"
                        selectedOptions={selectedOptions}
                        onChange={({detail}) => {
                            const newValue = detail.selectedOptions.map(option => option.value).join(',');
                            onChange(newValue);
                            setSelectedOptions(detail.selectedOptions);
                        }}
                        options={formOptions}
                        filteringType="auto"
                        placeholder="Choose options"
                        tokenLimit={4}
                    />
                </FormField>
            </div>
        );
    }

export const getDateForm =() => ({ value, onChange }: {value: string, onChange: any}) => (
    <div className="date-form">
        <FormField >
            <DateInput
                value={value ?? ""}
                onChange={event =>
                    onChange(event.detail.value)
                }
                placeholder="YYYY/MM/DD"
            />
        </FormField>
        <Calendar
            value={value ?? ""}
            onChange={event =>
                onChange(event.detail.value)
            }
            locale="en-GB"
            todayAriaLabel="Today"
            nextMonthAriaLabel="Next month"
            previousMonthAriaLabel="Previous month"
            isDateEnabled = {(date) => date.getTime() >= START_TIME_MIN}
        />
    </div>
)

export const filterPropertyConfigMap: Record<string, PropertyFilterProps.FilteringProperty> = {
    [DataStoreFields.WORKFLOW_STATUS] : {
        key: DataStoreFields.WORKFLOW_STATUS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Workflow status',
        groupValuesLabel: "Workflow status's"
    },
    [DataStoreFields.PROGRAM] : {
        key: DataStoreFields.PROGRAM,
        propertyLabel: 'Program',
        groupValuesLabel: "Program's",
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form:getMultiselectForm(Object.values(PROGRAM_NAMES_ENUM)),
            format: values => values
        }))
    },
    [DataStoreFields.STAGE_NAME] : {
        key: DataStoreFields.STAGE_NAME,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Stage',
        groupValuesLabel: "Stage's"
    },
    [DataStoreFields.DOMAIN] : {
        key: DataStoreFields.DOMAIN,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Domain',
        groupValuesLabel: "Domain's"
    },
    [DataStoreFields.STAGE_ERROR] : {
        key: DataStoreFields.STAGE_ERROR,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL, FilterTokenOperators.REGEX],
        propertyLabel: 'Stage Error',
        groupValuesLabel: "Stage Error's"
    },
    [DataStoreFields.ERROR] : {
        key: DataStoreFields.ERROR,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL, FilterTokenOperators.REGEX],
        propertyLabel: 'Workflow Error',
        groupValuesLabel: "Workflow Error's"
    },
    [DataStoreFields.STAGE_STATUS]: {
        key: DataStoreFields.STAGE_STATUS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Stage Status',
        groupValuesLabel: "Stage Status's"
    },
    [DataStoreFields.MARKET_PLACE]: {
        key: DataStoreFields.MARKET_PLACE,
        propertyLabel: 'Market Place',
        groupValuesLabel: "Market Places's",
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form:getMultiselectForm(TARGETED_WEBSITES),
            format: values => values
        }))
    },
    [DataStoreFields.WORKFLOW_CREATE_TIMESTAMP]:                 {
        key: DataStoreFields.WORKFLOW_CREATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow creation time",
        groupValuesLabel: "Workflow creation times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.STAGE_LAST_UPDATE_TIMESTAMP]:  {
        key: DataStoreFields.STAGE_LAST_UPDATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Stage last update time",
        groupValuesLabel: "Stage last update times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.ATTEMPT_LAST_UPDATE_TIMESTAMP]:  {
        key: DataStoreFields.ATTEMPT_LAST_UPDATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Attempt last update time",
        groupValuesLabel: "Attempt last update times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.WORKFLOW_LAST_UPDATE_TIMESTAMP]:  {
        key: DataStoreFields.WORKFLOW_LAST_UPDATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow last update time",
        groupValuesLabel: "Workflow last update times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.STAGE_CREATION_TIMESTAMP]:  {
        key: DataStoreFields.STAGE_CREATION_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Stage creation time",
        groupValuesLabel: "Stage creation times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.FAILED_WORKFLOW_TYPE]: {
        key: DataStoreFields.FAILED_WORKFLOW_TYPE,
        propertyLabel: "Failed workflow type",
        groupValuesLabel: "Failed workflow types",
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form:getMultiselectForm(FAILED_WORKFLOW_TYPE_OPTIONS),
            format: values => values
        }))
    },
    [DataStoreFields.SUB_PROGRAM] : {
        key: DataStoreFields.SUB_PROGRAM,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm(getSubProgramList()),
                format: values => values
            }
        ],
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        propertyLabel: 'Sub Program',
        groupValuesLabel: "Sub Programs"
    },
    [DataStoreFields.WORKFLOW_START_TIME]:                 {
        key: DataStoreFields.WORKFLOW_START_TIME,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow start time",
        groupValuesLabel: "Workflow start times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.WORKFLOW_END_TIME]:                 {
        key: DataStoreFields.WORKFLOW_END_TIME,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow end time",
        groupValuesLabel: "Workflow end times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.PRODUCT] : {
        key: DataStoreFields.PRODUCT,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm([]),
                format: values => values
            }
        ],
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        propertyLabel: 'Product',
        groupValuesLabel: "Products"
    },
    [DataStoreFields.USE_CASE] : {
        key: DataStoreFields.USE_CASE,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm([...PRODUCT_STRATEGY_CONSTANTS, ...getSubProgramList(), ...Object.values(PROGRAM_NAMES_ENUM)]),
                format: values => values
            }
        ],
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        propertyLabel: 'Product Strategy',
        groupValuesLabel: "Product Strategy's"
    },
    [DataStoreFields.VARIANT] : {
        key: DataStoreFields.VARIANT,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm(getSubProgramList()),
                format: values => values
            }
        ],
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        propertyLabel: 'Variant',
        groupValuesLabel: "Variant's"
    }
}
