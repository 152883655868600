import React from 'react';
import {useQueries, UseQueryResult} from "@tanstack/react-query";
import {
    cycleTimeSummary,
    workItemSummary
} from "../../../../test/test-helpers/sm-overview-test-data";
import {SMSummary} from "src/model/SMSummary";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {
    SummaryDataResult,
    SummaryDataResultWithAggregationKey,
    SummaryDataView
} from "src/interfaces/SMSummaryInterface";
import {PROGRAM_PAGE_ZERO_VISIBILITY} from "src/constants/sm-overview-dashboard-constants";
import {PROGRAM_NAMES_ENUM} from "src/constants/workflow-instance";

export const transformSummaryData = (summaryDataResult: SummaryDataResult) => {
    return summaryDataResult.reduce(
        (completeSummaryData: SummaryDataView, queryResult: UseQueryResult<SMSWOUnifiedDashboardServiceLambda.Types.GetProgramSummaryResponse, unknown>
            | UseQueryResult<SMSWOUnifiedDashboardServiceLambda.Types.GetWorkItemAndFailureSummaryResponse, unknown> | UseQueryResult<SMSWOUnifiedDashboardServiceLambda.Types.GetCycleTimeSummaryResponse, unknown>) => {
            queryResult?.data?.summaryData?.forEach((data) => {
                const program = data['program'];
                if(PROGRAM_PAGE_ZERO_VISIBILITY[program as PROGRAM_NAMES_ENUM]){
                    let currentProgramData = completeSummaryData[`${program}`] || {};
                    completeSummaryData[`${program}`] = {...currentProgramData, ...data};
                }
            })
            return completeSummaryData;
        }, {})
}

export const useProgramSummaryData = (filterQuery: string) => {
    /* istanbul ignore next */
    const summaryDataResult = useQueries({
        queries: [
            {queryKey: ['programSummary', filterQuery], queryFn: () => new SMSummary().getSummaryData('PROGRAM', filterQuery), enabled: !!filterQuery},
            {queryKey: ['workItemSummary', filterQuery], queryFn: () => new SMSummary().getSummaryData('WORKITEM_AND_FAILURE', filterQuery), enabled: !!filterQuery},
            {queryKey: ['cycleTimeSummary', filterQuery], queryFn: () => new SMSummary().getSummaryData('CYCLE_TIME', filterQuery), enabled: !!filterQuery}
        ],
    })

    const isDataLoading = summaryDataResult.some((dataResult) => dataResult.isLoading);

    return {
        summaryData: isDataLoading ? [] : transformSummaryData(summaryDataResult),
        loading: isDataLoading,
        hasErrors: summaryDataResult.some((dataResult) => dataResult.isError),
        errors: summaryDataResult.map((dataResult) => dataResult.error)
    }
}

export const useSummaryDataWithAggregationKey = (filterQuery: string, aggregationKey: string) => {
    /* istanbul ignore next */
    const summaryDataResult = useQueries({
        queries: [
            {queryKey: ['workflowSummary', filterQuery], queryFn: () => new SMSummary().getSummaryDataWithAggregationKey('WORKFLOW', filterQuery, aggregationKey), enabled: !!filterQuery},
            {queryKey: ['workflowAndWorkitemSummary', filterQuery], queryFn: () => new SMSummary().getSummaryDataWithAggregationKey('WORKFLOW_AND_WORKITEM_FAILURE', filterQuery, aggregationKey), enabled: !!filterQuery},
            {queryKey: ['cycleTimeSummaryV1', filterQuery], queryFn: () => new SMSummary().getSummaryDataWithAggregationKey('CYCLE_TIME_V1', filterQuery, aggregationKey), enabled: !!filterQuery}
        ],
    })

    const isDataLoading = summaryDataResult.some((dataResult) => dataResult.isLoading);

    return {
        summaryData: isDataLoading ? [] : transformSummaryDataWithAggregationKey(summaryDataResult),
        loading: isDataLoading,
        hasErrors: summaryDataResult.some((dataResult) => dataResult.isError),
        errors: summaryDataResult.map((dataResult) => dataResult.error)
    }
}


export const transformSummaryDataWithAggregationKey = (summaryDataResult: SummaryDataResultWithAggregationKey) => {
    return summaryDataResult.reduce(
        (completeSummaryData: SummaryDataView, queryResult: UseQueryResult<SMSWOUnifiedDashboardServiceLambda.Types.GetWorkflowSummaryResponse, unknown>
            | UseQueryResult<SMSWOUnifiedDashboardServiceLambda.Types.GetWorkflowAndWorkitemFailureSummaryResponse, unknown> | UseQueryResult<SMSWOUnifiedDashboardServiceLambda.Types.GetCycleTimeSummaryResponseV1, unknown>) => {
            queryResult?.data?.summaryData?.forEach((data) => {
                const aggregationKey = data['aggregationKey'];
                let currentAggregationData = completeSummaryData[`${aggregationKey}`] || {};
                completeSummaryData[`${aggregationKey}`] = {...currentAggregationData, ...data};
            })
            return completeSummaryData;
        }, {})
}